import { ApiError } from '../../errors';
import { CCApiService } from './api-service';
import { UIConfig } from './interfaces';

export class FusionIntegrationApiService {
  static ApiService: CCApiService;
  static apiBasePath: string;
  static customerId: string;

  static init(config: UIConfig, customerId: string) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.customerId = customerId;
  }

  static async getFusionInstances(type, filter: {}): Promise<any> {
    const queryParams = {
      type,
      ...filter,
    };
    const url = `${this.apiBasePath}/customers/${this.customerId}/integratedsystems?${new URLSearchParams(
      queryParams
    )}`;
    return this.ApiService.performFetch(url).then(res => res);
  }

  static async getFusionInstance(fusionId: string): Promise<any> {
    return this.ApiService.performFetch(
      `${this.apiBasePath}/customers/${this.customerId}/integratedsystems/${fusionId}`
    ).then(res => res);
  }

  static async createFusionInstance({ fusionInstance }) {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(fusionInstance),
    };
    return this.ApiService.performFetch(
      `${this.apiBasePath}/customers/${this.customerId}/integratedsystems`,
      fetchOptions
    ).catch(this._mapApiErrorCodeToMessage);
  }

  static async updateFusionInstance({ fusionInstance }) {
    const fetchOptions = {
      method: 'PUT',
      body: JSON.stringify(fusionInstance),
    };
    const url = `${this.apiBasePath}/customers/${this.customerId}/integratedsystems/${fusionInstance.id}`;
    return this.ApiService.performFetch(url, fetchOptions).catch(this._mapApiErrorCodeToMessage);
  }

  static async deleteFusionInstance(id: string): Promise<any> {
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(
      `${this.apiBasePath}/customers/${this.customerId}/integratedsystems/${id}`,
      fetchOptions
    ).then(res => res);
  }

  static _mapApiErrorCodeToMessage(error) {
    if (error instanceof ApiError && error.message === 'E0000038') {
      error.message = 'The Managed Fusion Install cannot be added. Please contact customer support.';
    }
    if (error instanceof ApiError && error.message === 'E0000001') {
      error.message = 'redirect_uris must be an array of absolute URIs.';
    }
    throw error;
  }
}
